import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import ForgotPasswordContainer from "./Common/ForgotPasswordContainer";
import ArrowBack from "../assets/images/back_arrow.svg";
import { withRouter } from 'react-router-dom';
import ForgotpassImage from "../assets/images/forgotpass.png";
import { Typography, Button, CircularProgress } from '@material-ui/core';
import OutlinedInput from "./Common/Input/OutlinedInput";
import {forgotPassword} from "../redux/forgotpassword/action";
import Alert from '@material-ui/lab/Alert';
import * as Validator from "../util/Validation";

import Image from 'Components/Common/image.jsx'
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
    isProgress:state.forgotPassword.isProgress,
    error:state.forgotPassword.error,
    success:state.forgotPassword.success,
});

const connectionActions = {
    forgotPassword:forgotPassword
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        // height: "100vh",
        margin: "0 auto",
    },
    backImage: {
        cursor: "pointer",
        width:theme.spacing(5.8)
    },
    titleImage:{
        width: "45"
    },
    outlinedInput: {
        "& > div": {
            textAlign: "center"
        }
    },
    content: {
        margin: "0 auto",
        textAlign: "center",
        paddingTop: theme.spacing(1),
         "& [class*='MuiFormControl-root']": {
            marginTop: theme.spacing(2),
            maxWidth: "644px"
         },

         

        "& [class*='Outlined-outlinedInput']": {
           // paddingLeft: theme.spacing(5.6)
        }
    },
    heading: {
        marginTop: theme.spacing(2.8),
        fontWeight:700,
        color:"#333333",
        fontSize:theme.spacing(3.2)
    },
    sendBtn: {
        padding: theme.spacing(0.8, 4),
        fontSize:theme.spacing(2.8),
        fontWeight:500,
        marginTop: theme.spacing(0.3)
    },
    caption: {
        marginTop: theme.spacing(2.8),
        marginBottom: theme.spacing(4),
    },
    captionText :{
        color:"#333333",
        fontSize:theme.spacing(2.8),
        lineHeight: "31px"
    },
    action: {
        marginTop: theme.spacing(8),
    },
    alert:{
        width: 646,
        margin: 'auto'
    }

});

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'email': "",
            'email_error':"",
            "success":"",
            "error":""
        }

        this.forgotPassword   =   this.forgotPassword.bind(this);
        this.clearError     =   this.clearError.bind(this);
    }

    componentDidMount(){
        window.open(`${getNewPlatform()}/${window.location.pathname}`,'_self')
    }

    componentDidUpdate(prevProps){
        if(this.props.isProgress === false && prevProps.isProgress === true){
            if(this.props.error !== null){
                var error   =   this.props.error;
                if(error.status == 400){
                    var errors  =   error.errors;
                    var stateError  =   {
                        'email_error':"",
                    };
                    for(var field in errors){
                        switch(field){
                            case "email":
                                stateError.email_error  =   errors[field][0];
                                break;
                        }
                    }
                    this.setState(stateError)
                } else {
                    this.setState({error:error.message});
                }
            } else {
                this.setState({
                    success:this.props.success.message,
                    email: "",
                })
            }
        }
    }


    clearError(){
        this.setState({
            success:"",
            error:"",
            email_error:""
        });
    }

    forgotPassword(){
        var me  =   this;
        me.clearError();
        var email   =    me.state.email;
        if(email == null || String(email).trim().length == 0){
            me.setState({email_error:"Please provide your email."});
            return;
        }

        if(Validator.validateEmail(email) === false){
            me.setState({email_error:"Please provide valid email."});
            return;
        }
        this.props.forgotPassword(email);
    }

    render() {
        return <div></div>
    }

    // render() {
    //     const classes = this.props.classes;
    //     return <ForgotPasswordContainer>
    //         <div className={classes.root}>
    //             <div className={classes.backBtn}>
    //                 <Image 
    //                     src={ArrowBack}
    //                     alt="back" 
    //                     className={classes.backImage}
    //                     onClick={() => {
    //                         this.props.history.goBack();
    //                     }}
    //                 />
    //             </div>
    //             <div className={classes.content}>
    //                 <Image 
    //                     src={ForgotpassImage}
    //                     alt="forgotpassword_image" 
    //                     className={classes.titleImage}
    //                 />
    //                 <Typography variant={"h6"} className={classes.heading}>Forgot Password?</Typography>
    //                 <div className={classes.caption}>
    //                     <Typography className={classes.captionText}>Enter the email address associated with your account,</Typography>
    //                     <Typography className={classes.captionText}>and we’ll email you a link to reset your password.</Typography>
    //                 </div>
    //                 <div className={classes.alert}>
    //                     {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
    //                     {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
    //                 </div>
    //                 <div className={classes.outlinedInput}>
    //                     <OutlinedInput 
                        
    //                         id={"emailIdInput"}
    //                         placeholder={"Enter your Email here"} 
    //                         value={this.state.email}
    //                         onChange={(event) => {
    //                             this.setState({ email: event.target.value });
    //                         }}
    //                         error={this.state.email_error.length > 0}
    //                         helperText={this.state.email_error}
    //                     />
    //                     </div>
    //                 <div className={classes.action}>
    //                     {!this.props.isProgress && <Button variant="contained" color="secondary" className={classes.sendBtn} onClick={this.forgotPassword}>Send reset link</Button>}
    //                     {this.props.isProgress && <CircularProgress />}
    //                     {/* <Button variant="contained" color="secondary" className={classes.sendBtn} onClick={() => {
    //                         this.props.history.push("/reset-password")
    //                     }}>Send Reset Link</Button> */}
    //                 </div>
    //             </div>
    //         </div>
    //     </ForgotPasswordContainer>
    // }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ForgotPassword));