import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AuthContainer from "./Common/AuthContainer";
import Logo from "../assets/images/colour_logo.svg";
import { Typography, Button, CircularProgress } from '@material-ui/core';
import OutlinedInput from "./Common/Input/OutlinedInput";
import { Link, withRouter } from "react-router-dom";
// import * as Validator from "../util/Validation";
import {registerSSO} from "../redux/register/action";
import Alert from '@material-ui/lab/Alert';
import * as URL from "../util/URL";
import Image from 'Components/Common/image.jsx'
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
    isProgress:state.register.isProgress,
    error:state.register.error,
    success:state.register.success,
});

const connectionActions = {
    register:registerSSO
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "registerForm": {
        textAlign: "center",
        paddingLeft:theme.spacing(5),
        paddingRight:theme.spacing(5),
        margin: "0 auto",
    },
    "caption": {
        marginTop: theme.spacing(3)
    },
    "heading": {
        marginBottom: theme.spacing(1),
        fontSize:theme.spacing(3.2),
        color: "#4A4A4A",
        letterSpacing: "0.11px"
    },
    "subtitle":{
        fontSize:theme.spacing(2.2),
        marginBottom: theme.spacing(0),
        fontWeight:600,
        color: "#4A4A4A",
    },
    "form": {
        marginTop: theme.spacing(6)
    },
    "forgotpassword_link": {
        "&:any-link": {
            color: theme.palette.secondary.main,
        },
        "&:any-link:active": {
            color: theme.palette.secondary.main,
        }
    },
    "forgotlabel": {
        textAlign: "right"
    },
    "formAction": {
        marginTop: theme.spacing(4),
        margin: "0 auto"
    },
    "button": {
        padding: theme.spacing(0.8, 11.6),
        fontSize:theme.spacing(2.8),
        fontWeight:500,
        marginTop: theme.spacing(.4)
    },

    "register_link": {
        marginTop: theme.spacing(3.2),
        display: "block"
    },
    "registerlable": {
        textAlign: "center",
        fontSize:theme.spacing(2.4),
        textDecoration:"underline",
        color:"#4A4A4A"
    },
    termLink: {
        textDecoration: 'underline'
    },
    termText: {
        margin: 0,
        paddingTop: 20
    },
    loginText: {
        margin: '0 auto'
    }
});

class SSORegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'name':"",
            'name_error':"",
            'company': "",
            "company_error":"",
            "success":"",
            "error":""
        }

        this.registerUser   =   this.registerUser.bind(this);
        this.clearError     =   this.clearError.bind(this);
    }

    componentDidMount(){
        window.open(`${getNewPlatform()}/${window.location.pathname}${window.location.search}`,'_self')
        var token   = URL.getQueryVariable("token");
        if(token == null){
            this.props.history.push("/cognito-login/error?error=internal")
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.isProgress === false && prevProps.isProgress === true){
            if(this.props.error !== null){
                var error   =   this.props.error;
                if(error.status == 400){
                    var errors  =   error.errors;
                    if(errors !== null){
                        var stateError  =   {
                            'name_error':"",
                            'email_error':"",
                            "company_error":"",
                        };
                        for(var field in errors){
                            switch(field){
                                case "name":
                                    stateError.name_error  =   errors[field][0];
                                    break;
                                case "company":
                                    stateError.company_error  =   errors[field][0];
                                    break;
                            }
                        }
                        this.setState(stateError)
                    } else {
                        this.setState({error:error.message});    
                    }
                } else {
                    this.setState({error:error.message});
                }
            } else {
                this.props.history.push("/splash")
            }
        }
    }

    clearError(){
        this.setState({
            name_error:"",
            email_error:"",
            company_error:"",
            success:"",
            error:""
        });
    }

    registerUser(){
        var me  =   this;
        me.clearError();
        var name    =   me.state.name;
        if(name == null || String(name).trim().length == 0){
            me.setState({name_error:"Please provide your name."});
            return;
        }
       

        var company     = me.state.company;
        if(company == null || String(company).trim().length == 0){
            me.setState({company_error:"Please provide your company detail."});
            return;
        }
        var token   = URL.getQueryVariable("token");
        me.props.register(token,name,company);
    }

    render() {
        return <div></div>
    }

    // render() {
    //     var classes = this.props.classes;
    //     return <AuthContainer>
    //         <div className={classes.registerForm}>
    //             <Image src={Logo} alt="logo" />
    //             <div className={classes.caption}>
    //                 <Typography variant={"h5"} className={classes.heading}>Expent</Typography>
    //                 <Typography className={classes.subtitle}>Create your Account!</Typography>
    //                 <p className={classes.loginText}>Already have an account? <Link to="/" className={classes.termLink}>Sign In</Link></p>
    //             </div>
    //             <div>
    //                 {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
    //                 {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
    //             </div>
    //             <div className={classes.form}>
    //                 <OutlinedInput
    //                     id={"nameInput"}
    //                     label="Name"
    //                     value={this.state.name}
    //                     onChange={(event) => {
    //                         this.setState({ name: event.target.value });
    //                     }}
    //                     error={this.state.name_error.length > 0}
    //                     helperText={this.state.name_error}
    //                 />
    //                 <OutlinedInput
    //                     id={"companyInput"}
    //                     label="Company"
    //                     value={this.state.company}
    //                     onChange={(event) => {
    //                         this.setState({ company: event.target.value });
    //                     }}
    //                     error={this.state.company_error.length > 0}
    //                     helperText={this.state.company_error}
    //                 />
    //             </div>
            
    //             <div className={classes.formAction}>
    //                 {!this.props.isProgress && <Button variant="contained" color="secondary" className={classes.button} onClick={this.registerUser}>Signup</Button>}
    //                 {this.props.isProgress && <CircularProgress />}
    //             </div>
    //             <div><p className={classes.termText}>By clicking Signup, you agree to our <a className={classes.termLink} href="https://www.expent.ai/terms" target="_blank" rel="noopener noreferrer">General Terms of Use</a> and you acknowledge our <a className={classes.termLink} href="https://www.expent.ai/privacy" target="_blank" rel="noopener noreferrer" >Privacy Statement</a>.</p></div>
    //         </div>
    //     </AuthContainer>
    // }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SSORegister));