import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AuthContainer from "./Common/AuthContainer";
import Logo from "../assets/images/colour_logo.svg";
import { Typography } from '@material-ui/core';
// import OutlinedInput from "./Common/Input/OutlinedInput";
import { Link, withRouter } from "react-router-dom";
import {login} from "../redux/login/action";
// import Alert from '@material-ui/lab/Alert';
// import * as Validator from "../util/Validation";
import * as URL from "../util/URL";

import Image from 'Components/Common/image.jsx'
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
    isProgress:state.login.isProgress,
    error:state.login.error,
    success:state.login.success,
});

const connectionActions = {
    login:login
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "loginform": {
        textAlign: "center",
        paddingBottom: theme.spacing(3.6),
        paddingLeft:theme.spacing(5),
        paddingRight:theme.spacing(5),
        margin: "0 auto",

    },
    "caption": {
        marginTop: theme.spacing(2)
    },
    "heading": {
        marginBottom: theme.spacing(1),
        fontSize:theme.spacing(4.2),
        color: "#4A4A4A",
    },
    mesasge:{
        marginBottom: theme.spacing(1),
        fontSize:theme.spacing(4.2),
        color: "#f00",
    },
    "subtitle":{
        fontSize:theme.spacing(2.2),
        marginBottom: theme.spacing(6.4),
        fontWeight:600,
        color: "#4A4A4A",
    },
    "form": {
        marginTop: theme.spacing(5),

        "& [class*=MuiFormControl-root]": {
            marginBottom: theme.spacing(1)
        },

        "& [class*=MuiOutlinedInput-inputRoot]": {
            minHeight: '54px'
        }
        
    },
    "forgotpassword_link": {
        "&:any-link": {
            color: theme.palette.secondary.main,
        },
        "&:any-link:active": {
            color: theme.palette.secondary.main,
        }
    },
    "forgotlabel": {
        textAlign: "right",
        fontSize: theme.spacing(2.4),
        textDecoration:"underline",
        color:"#4A4A4A"
    },
    "formAction": {
        marginTop: theme.spacing(4),
        margin: "0 auto"
    },
    "button": {
        padding: theme.spacing(0.8, 12.4),
        fontSize:theme.spacing(2.8),
        fontWeight:500,
        marginTop: theme.spacing(1.2)
    },
    "register_link": {
        marginTop: theme.spacing(5.6),
        display: "block"
    },
    "registerlable": {
        textAlign: "center",
        fontSize:theme.spacing(2.4),
        textDecoration:"underline",
        color:"#4A4A4A"
    }
});

class CognitoError extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        window.open(`${getNewPlatform()}/${window.location.pathname}`,'_self')
    }

    render() {
        return <div></div>
    }

    // render() {
    //     var classes = this.props.classes;
    //     var error   = URL.getQueryVariable("error");
    //     var message     =   "Internal Server Error.";
    //     if(error == "failedlogin"){
    //         message  = "Failed to Login";
    //     } else if (error == "backlisted"){
    //         message  = "Email used is not allowed to login. Please use your corporate email.";
    //     } else if( error == "nossoaccount"){
    //         message  = "Please login with password, as account is not enabled for SSO.";
    //     }
        
    //     return <AuthContainer>
    //         <div className={classes.loginform}>
    //             <Image src={Logo} alt="logo" />
    //             <div className={classes.caption}>
    //                 <Typography variant={"h5"} className={classes.heading}>Expent</Typography>
    //             </div>
    //             <Typography variant={"h5"} className={classes.mesasge}>{message}</Typography>
    //             <Link to={"/"} className={classes.register_link}><Typography className={classes.registerlable}>Go Back to Login</Typography></Link>
    //         </div>
    //     </AuthContainer>
    // }   
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CognitoError));